


































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import AllianzDepositFlexibleInformationViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/flexible_deposits/allianz-deposit-flexible-information-view-model';

@Component({
  name: 'FlexibleDeposit',
})
export default class FlexibleDeposit extends Vue {
  allianz_deposit_flexible_view_model = Vue.observable(
    new AllianzDepositFlexibleInformationViewModel(this),
  );

  created() {
    this.allianz_deposit_flexible_view_model.initialize();
  }
}

