import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

import Vue from 'vue';

// Application
import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';

// Domain
import { BankNameFormatter } from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/services';
import {
  AllianzAccountReportEntity,
} from '@/modules/my-investment/allianz/allianz-account-report/domain/entities/allianz-account-report-entity';
import {
  WealthFlexibleDepositStateManager,
} from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositFlexibleInformationViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  private readonly wealth_flexible_deposit_state_manager!: WealthFlexibleDepositStateManager;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly view: Vue;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.flexible_deposit';

  is_loading = false;

  is_valid_form = false;

  policy_number = '';

  funds: Array<AllianzAccountReportEntity> = [];

  recurrent_assigned_amount = 0;

  inputs = {
    source_account: '',
    destination_account: {
      id: '',
      customer_id: '',
      allianz_account_id: '',
      transaction_id: '',
      request_number: '',
      product: '',
      policy_number: 0,
      client_code: '',
      client_name: '',
      activation_date: '',
      beginning_of_validity_date: '',
      agent_office: 0,
      agent_code: '',
      agent_name: '',
      promoter_code: '',
      promoter_name: '',
      grouper_code: '',
      grouper_name: '',
      advisor_code: '',
      advisor_name: '',
      item: '',
      found_code: '',
      found_name: '',
      amount_contributed: 0.00,
      withdrawal_amount: 0.00,
      charge_amount: 0.00,
      units: 0.00,
      units_value: 0.00,
      final_balance: 0.00,
      final_balance_mxn: 0.00,
      last_payment_date: '',
      updated_at: '',
      created_at: '',
    },
    amount: '',
  };

  input_rules = {
    source_account: [
      requiredRule,
    ],
    destination_account: [
      requiredRule,
    ],
    amount: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) >= 500
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$500.00 MXN' })
      ),
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) !== this.recurrent_assigned_amount
        || this.translate_errors('utils.form-rules.recurrent_amount_equal_flexible_amount')
      ),
    ],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  reset = () => {
    this.inputs.amount = '';
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  loadPosition = async () => {
    try {
      this.funds = await this.search_allianz_account_report_query.execute(this.customer_id);
      const found_founded = this.funds.find((fund) => fund.found_code === 'SWSRFP');
      if (found_founded) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.inputs.destination_account = found_founded;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_position'));
    }
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  specialContributionSelected = () => {
    this.view.$emit('specialContributionSelected', 2);
  }

  verifyDialogToShow = () => {
    const parsed_amount = parseFloat(this.inputs.amount.replaceAll(/[^0-9]/g, ''));
    this.wealth_flexible_deposit_state_manager.patch({ deposit: this.inputs });
    if (parsed_amount > 50000) {
      this.specialContributionSelected();
    } else {
      this.nextStep();
    }
  }

  initialize = async () => {
    this.is_loading = true;
    const {
      bank, account, policy_number_issued, amount,
    } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
    await this.loadPosition();
    this.recurrent_assigned_amount = amount || 0;
    const formatted_bank_name = BankNameFormatter.format(bank);
    this.inputs.source_account = `${formatted_bank_name} **** ${account.substr(account.length - 4)}`;
    this.policy_number = policy_number_issued;
    this.is_loading = false;
  }
}
